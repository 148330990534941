import { CssBaseline, GlobalStyles as MuiGlobalStyles } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../styles/theme.js'

const GlobalStyles = ({ children }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <MuiGlobalStyles
      styles={{
        '@keyframes fadeIn': {
          '0%': {
            opacity: 0,
            transform: 'translateY(-20px)',
          },
          '100%': {
            opacity: 1,
            transform: 'translateY(0)',
          },
        },
        '@keyframes bounce': {
          '0%, 100%': {
            transform: 'translateY(0)',
          },
          '50%': {
            transform: 'translateY(-10px)',
          },
        },
        'html, body': {
          height: '100%',
          margin: 0,
          padding: 0,
          backgroundColor: theme.palette.background.default,
          backgroundAttachment: 'scroll', // Remove 'fixed' for mobile compatibility
          backgroundSize: 'cover',
          color: '#FFFFFF',
          overflowX: 'hidden', // Prevent horizontal scrolling
          overflowY: 'auto', // Enable vertical scrolling
          WebkitOverflowScrolling: 'touch', // Smooth scrolling on iOS
          overscrollBehavior: 'none', // Disable bounce/overscroll
        },
        '#root': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto', // Ensure #root is scrollable
        },
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
        },
        '::-webkit-scrollbar': {
          width: '8px',
        },
        '::-webkit-scrollbar-track': {
          background: '#1c1f26',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#5b8def',
          borderRadius: '4px',
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: '#3b6fb3',
        },
        '*': {
          scrollbarColor: '#2e2e2e #000',
          scrollbarWidth: 'thin',
        },
      }}
    />
    {children}
  </ThemeProvider>
);

export default GlobalStyles;
