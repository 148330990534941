import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  MenuItem,
  IconButton,
  Paper,
  InputAdornment,
  AppBar,
  Toolbar,
  useMediaQuery
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ToolBarIcon from '@mui/icons-material/AutoGraph';
import StopIcon from '@mui/icons-material/Stop';
import ArticleIcon from '@mui/icons-material/Article';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import api from '../utils/api';
import '../styles/styles.css';

const Simulation = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const currentDate = new Date().toLocaleString('en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
  // State variables
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [error, setError] = useState('');
  const [quantity, setQuantity] = useState('');
  const [risk, setRisk] = useState('');
  const [strategy, setStrategy] = useState('openingRange');
  const [logs, setLogs] = useState('');
  const [simulationStatus, setSimulationStatus] = useState('Not Started');
  const [isSimulationRunning, setIsSimulationRunning] = useState(false);

  const pollingIntervalRef = useRef(null);

  const strategies = [
    { value: 'openingRange', label: 'Opening Range' },
    { value: 'openingReversal', label: 'Opening Reversal' },
  ];

  const handleSimulation = async () => {
    setLoading(true);
    setError('');
    setResponseMessage('');
    setLogs('');
    try {
      if (!quantity || !risk || !strategy) {
        setError('Please fill in all fields (Quantity, Risk, and Strategy).');
        setLoading(false);
        return;
      }
      const response = await api.get('/simulation', {
        params: { quantity, risk, strategy },
      });
      setResponseMessage(response.data.message);
      setIsSimulationRunning(true);
      setSimulationStatus('Running');
    } catch (err) {
      console.error(err);
      setError('Failed to execute simulation. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleStopSimulation = async () => {
    setLoading(true);
    setError('');
    setResponseMessage('');
    setLogs('');
    try {
      const response = await api.post('/stop_simulation');
      setResponseMessage(response.data.message || 'Stop requested.');
    } catch (err) {
      console.error(err);
      setError('Failed to stop simulation. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleShowLogs = async () => {
    setLoading(true);
    setError('');
    setResponseMessage('');
    setLogs('');
    try {
      const response = await api.get('/logs');
      const logsData = Array.isArray(response.data.logs)
        ? response.data.logs.join('\n')
        : response.data.logs;
      setLogs(logsData || 'No logs available.');
    } catch (err) {
      console.error(err);
      setError('Failed to retrieve logs. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleResetAndClose = () => {
    setQuantity('');
    setRisk('');
    setStrategy('openingRange');
    setResponseMessage('');
    setError('');
    setLogs('');
    setIsSimulationRunning(false);
    setSimulationStatus('Not Started');
    navigate(-1);
  };

  // Fetch simulation status from server
  const fetchSimulationStatus = async () => {
    try {
      const response = await api.get('/simulation_status');
      const currentStatus = response.data.status;
      setSimulationStatus(currentStatus);

      // If the simulation is no longer running, stop polling
      if (currentStatus === 'Stopped' || currentStatus === 'Completed') {
        setIsSimulationRunning(false);
      }
    } catch (err) {
      console.error('Failed to fetch simulation status:', err);
    }
  };

  // Start polling when simulation starts
  useEffect(() => {
    if (isSimulationRunning) {
      pollingIntervalRef.current = setInterval(fetchSimulationStatus, 5000);
    } else {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }
    }

    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, [isSimulationRunning]);

  const incrementQuantity = () => {
    setQuantity((prev) => {
      const val = Number(prev) || 0;
      return (val + 1).toString();
    });
  };

  const decrementQuantity = () => {
    setQuantity((prev) => {
      const val = Number(prev) || 0;
      return Math.max(val - 1, 0).toString();
    });
  };

  const incrementRisk = () => {
    setRisk((prev) => {
      const val = Number(prev) || 0;
      return (val + 0.1).toFixed(2).toString();
    });
  };

  const decrementRisk = () => {
    setRisk((prev) => {
      const val = Number(prev) || 0;
      const newVal = Math.max(val - 0.1, 0).toFixed(2);
      return newVal.toString();
    });
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        p: 2,
      }}
    >
      {/* Top AppBar */}
      <AppBar
        position="static"
        sx={{
          mb: 3,
          background: 'linear-gradient(to right, #0a0c10 30%, #0a0c10 90%)',
          boxShadow: '0px 4px 10px rgba(0,0,0,0.5)',
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box display="flex" alignItems="center">
            <ToolBarIcon sx={{ mr: 1, color: theme.palette.secondary.main }} />
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Simulation
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            {!isMobile && (
              <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
                {currentDate}
              </Typography>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          width: '100%',
          maxWidth: '1200px',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        {/* Two-column layout container */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 3,
            flexWrap: 'wrap', // Allow wrapping if screen is smaller
          }}
        >
          {/* Card for Input Fields (Left Column) */}
          <Card
            sx={{
              flex: '1 1 300px',
              borderRadius: '16px',
              border: `1px solid ${theme.palette.divider}`,
            }}
          >
            <CardHeader
              title="Simulation Parameters"
              sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
            />
            <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {/* Quantity Field */}
              <TextField
                label="Quantity"
                type="number"
                color="transparent"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                placeholder="e.g. 100"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={decrementQuantity}>
                        <RemoveIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={incrementQuantity}>
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  inputProps: {
                    style: {
                      MozAppearance: 'textfield'
                    },
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: theme.palette.secondary.main },
                    '&:hover fieldset': { borderColor: theme.palette.secondary.light },
                    '&.Mui-focused fieldset': { borderColor: theme.palette.secondary.main },
                    '& input': {
                      color: theme.palette.text.primary,
                      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                      },
                    },
                  },
                  '& .MuiInputLabel-root': { color: theme.palette.text.secondary },
                }}
              />

              {/* Risk Field (supports decimals) */}
              <TextField
                label="Risk"
                type="number"
                value={risk}
                color="transparent"
                onChange={(e) => setRisk(e.target.value)}
                placeholder="e.g. 0.5"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={decrementRisk}>
                        <RemoveIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={incrementRisk}>
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  inputProps: {
                    step: 0.01,
                    style: {
                      MozAppearance: 'textfield'
                    },
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: theme.palette.secondary.main },
                    '&:hover fieldset': { borderColor: theme.palette.secondary.light },
                    '&.Mui-focused fieldset': { borderColor: theme.palette.secondary.main },
                    '& input': {
                      color: theme.palette.text.primary,
                      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                      },
                    },
                  },
                  '& .MuiInputLabel-root': { color: theme.palette.text.secondary },
                }}
              />

              {/* Strategy Field */}
              <TextField
                label="Strategy"
                color="transparent"
                select
                value={strategy}
                onChange={(e) => setStrategy(e.target.value)}
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: theme.palette.secondary.main },
                    '&:hover fieldset': { borderColor: theme.palette.secondary.light },
                    '&.Mui-focused fieldset': { borderColor: theme.palette.secondary.main },
                    '& input': { color: theme.palette.text.primary },
                  },
                  '& .MuiInputLabel-root': { color: theme.palette.text.secondary },
                }}
              >
                {strategies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </CardContent>
          </Card>

          {/* Card for Execution (Right Column) */}
          <Card
            sx={{
              flex: '1 1 300px',
              borderRadius: '16px',
              border: `1px solid ${theme.palette.divider}`,
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 2
            }}
          >
            <CardHeader
              title="Execution"
              sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                p: 3,
                gap: 3,
                width: '100%',
                flexGrow: 1
              }}
            >
              {/* Start Simulation Card */}
              <Paper
                elevation={2}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2,
                  borderRadius: '12px',
                  background: theme.palette.background.paper,
                  transition: 'box-shadow 0.1s ease, transform 0.3s ease',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: `0px 0px 6px 2px ${theme.palette.secondary.main}`,
                  },
                }}
                onClick={handleSimulation}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: theme.palette.text.primary }} />
                ) : (
                  <PlayArrowIcon />
                )}
                <Typography>Start Simulation</Typography>
              </Paper>

              {/* Stop Simulation Card */}
              <Paper
                elevation={2}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2,
                  borderRadius: '12px',
                  background: theme.palette.background.paper,
                  transition: 'box-shadow 0.1s ease, transform 0.3s ease',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: `0px 0px 6px 2px ${theme.palette.secondary.main}`,
                  },
                }}
                onClick={handleStopSimulation}
              >
                <StopIcon />
                <Typography>Stop Simulation</Typography>
              </Paper>

              {/* Show Logs Card */}
              <Paper
                elevation={2}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2,
                  borderRadius: '12px',
                  background: theme.palette.background.paper,
                  transition: 'box-shadow 0.1s ease, transform 0.3s ease',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: `0px 0px 6px 2px ${theme.palette.secondary.main}`,
                  }
                }}
                onClick={handleShowLogs}
              >
                <ArticleIcon />
                <Typography>Show Logs</Typography>
              </Paper>
            </Box>
          </Card>
        </Box>

        {/* Card for Response, Error, Logs, and Simulation Status */}
        {(responseMessage || error || logs || simulationStatus !== 'Not Started') && (
          <Card
            sx={{
              borderRadius: '16px',
              border: `1px solid ${theme.palette.divider}`,
              textAlign: 'center',
            }}
          >
            <CardHeader
              title="Results"
              sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
            />
            <CardContent>
              {simulationStatus && simulationStatus !== 'Not Started' && (
                <Typography
                  sx={{
                    p: 2,
                    color: theme.palette.info.main,
                    fontWeight: 500,
                  }}
                >
                  Simulation Status: {simulationStatus}
                </Typography>
              )}

              {responseMessage && (
                <Typography
                  sx={{
                    p: 2,
                    color: theme.palette.success.main,
                    fontWeight: 500,
                  }}
                >
                  {responseMessage}
                </Typography>
              )}
              {error && (
                <Typography
                  sx={{
                    p: 2,
                    color: theme.palette.error.main,
                    fontWeight: 500,
                  }}
                >
                  {error}
                </Typography>
              )}
              {logs && (
                <Box
                  sx={{
                    p: 2,
                    color: theme.palette.text.primary,
                    fontWeight: 500,
                    whiteSpace: 'pre-wrap',
                    textAlign: 'left',
                    maxHeight: '200px',
                    overflowY: 'auto',
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: '8px',
                    backgroundColor: theme.palette.background.paper,
                  }}
                >
                  <Typography component="div">
                    {logs}
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        )}
      </Box>
    </Box>
  );
};

export default Simulation;
