import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import api from '../utils/api';

const Market = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  // States for API responses
  const [weeklyLoading, setWeeklyLoading] = useState(false);
  const [weeklyResponse, setWeeklyResponse] = useState('');
  const [weeklyError, setWeeklyError] = useState('');
  const [intradayLoading, setIntradayLoading] = useState(false);
  const [intradayResponse, setIntradayResponse] = useState('');
  const [intradayError, setIntradayError] = useState('');

  // States for date selection
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleWeeklyAnalysis = async () => {
    setWeeklyLoading(true);
    setWeeklyError('');
    setWeeklyResponse('');
    try {
      const params = {
        startDate: startDate?.toISOString().split('T')[0],
        endDate: endDate?.toISOString().split('T')[0],
      };
      const response = await api.get('/strategy', { params });
      setWeeklyResponse(response.data.message || 'Weekly analysis executed successfully.');
    } catch (err) {
      setWeeklyError('Failed to execute weekly analysis. Please try again.');
    } finally {
      setWeeklyLoading(false);
    }
  };

  const handleIntradayAnalysis = async () => {
    setIntradayLoading(true);
    setIntradayError('');
    setIntradayResponse('');
    try {
      const option = {
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
      };
      const response = await api.post('/market', option);
      setIntradayResponse(response.data.message || 'Intraday analysis executed successfully.');
    } catch (err) {
      setIntradayError('Failed to execute intraday analysis. Please try again.');
    } finally {
      setIntradayLoading(false);
    }
  };

  const handleResetAndClose = () => {
    setWeeklyResponse('');
    setWeeklyError('');
    setIntradayResponse('');
    setIntradayError('');
    navigate(-1);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          backgroundColor: theme.palette.background.default,
          display: 'flex',
          flexDirection: 'column',
          p: 2,
        }}
      >
        <Box
          sx={{
            maxWidth: '1200px',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          {/* Date Pickers */}
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <DateTimePicker
              label="Start Date & Time"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              slotProps={{
                textField: {
                  InputLabelProps: { style: { color: '#ffffff' } }, // Label color for dark theme
                  InputProps: {
                    sx: {
                      color: '#ffffff', // Text color for the input field
                      backgroundColor: 'transparent', // Dark input field background
                      borderRadius: '8px',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': { borderColor: '#90caf9' }, // Default border color
                        '&:hover fieldset': { borderColor: '#42a5f5' }, // Hover effect
                        '&.Mui-focused fieldset': { borderColor: '#1e88e5' }, // Focus effect
                      },
                    },
                  },
                },
                popper: {
                  sx: {
                    '& .MuiPaper-root': {
                      backgroundColor: theme.palette.background.paper, // Calendar popup background
                      color: '#ffffff', // Calendar text color
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: '12px',
                      padding: '8px',
                    },
                    '& .MuiPickersDay-dayWithMargin': {
                      color: '#e0e0e0', // Default day text color
                    },
                    '& .Mui-selected': {
                      border: `1px solid ${theme.palette.divider}`,
                      backgroundColor: '#0000000 !important', // Selected day background
                      color: `${theme.palette.secondary.main} !important`, // Selected day text color
                    },
                    '& .MuiPickersDay-root:hover': {
                      backgroundColor: '#616161', // Hover effect for days
                    },
                  },
                },
                actionBar: {
                  sx: {
                    '& .MuiButton-root': {
                      boxShadow: 'none', // Removes shadow
                      elevation: 0, // Removes elevation
                      color: theme.palette.text.primary, // Button text color
                      border: `1px solid ${theme.palette.divider}`,
                      backgroundColor: 'transparent', // Button background color
                      '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: `0px 0px 6px 2px ${theme.palette.secondary.main}`,
                      }
                    },
                  },
                }
              }}
            />

            <DateTimePicker
              label="End Date & Time"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              slotProps={{
                textField: {
                  InputLabelProps: { style: { color: '#ffffff' } },
                  InputProps: {
                    sx: {
                      color: '#ffffff',
                      backgroundColor: 'transparent',
                      borderRadius: '8px',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': { borderColor: '#90caf9' },
                        '&:hover fieldset': { borderColor: '#42a5f5' },
                        '&.Mui-focused fieldset': { borderColor: '#1e88e5' },
                      },
                    },
                  },
                },
                popper: {
                  sx: {
                    '& .MuiPaper-root': {
                      backgroundColor: theme.palette.background.paper,
                      color: '#ffffff',
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: '12px',
                      padding: '8px',
                    },
                    '& .MuiPickersDay-dayWithMargin': {
                      color: '#e0e0e0',
                    },
                    '& .Mui-selected': {
                      border: `1px solid ${theme.palette.divider}`,
                      backgroundColor: '#0000000 !important', // Selected day background
                      color: `${theme.palette.secondary.main} !important`, // Selected day text color
                    },
                    '& .MuiPickersDay-root:hover': {
                      backgroundColor: '#616161',
                    },
                  },
                },
                actionBar: {
                  sx: {
                    '& .MuiButton-root': {
                      boxShadow: 'none', // Removes shadow
                      elevation: 0, // Removes elevation
                      color: theme.palette.text.primary, // Button text color
                      border: `1px solid ${theme.palette.divider}`,
                      backgroundColor: 'transparent', // Button background color
                      '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: `0px 0px 6px 2px ${theme.palette.secondary.main}`,
                      }
                    },
                  },
                }
              }}
            />
          </Box>

          {/* Weekly Analysis */}
          {/* <Card
            sx={{
              flex: '1 1 200px',
              borderRadius: '16px',
              border: `1px solid ${theme.palette.divider}`,
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 2
            }}
          >
            <CardHeader
              title="Weekly Strategy Analysis"
              action={
                <IconButton onClick={handleWeeklyAnalysis} disabled={weeklyLoading}>
                  {weeklyLoading ? <CircularProgress size={24} /> : <PlayArrowIcon />}
                </IconButton>
              }
            />
              <CardContent
                sx={{
                  overflowY: 'auto',
                  maxHeight: '200px', // Adjust based on your design needs
                  width: '100%',
                }}
              >
              {weeklyResponse && <Typography>{weeklyResponse}</Typography>}
              {weeklyError && <Typography color="error">{weeklyError}</Typography>}
            </CardContent>
          </Card> */}

          {/* Intraday Analysis */}
          <Card
            sx={{
              flex: '1 1 300px',
              borderRadius: '16px',
              border: `1px solid ${theme.palette.divider}`,
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 2
            }}
          >
            <CardHeader
              title="Intraday Market Analysis"
              action={
                <IconButton onClick={handleIntradayAnalysis} disabled={intradayLoading}>
                  {intradayLoading ? <CircularProgress size={24} /> : <PlayArrowIcon />}
                </IconButton>
              }
            />
              <CardContent
                sx={{
                  overflowY: 'auto',
                  maxHeight: '200px', // Adjust based on your design needs
                  width: '100%',
                }}
              >              
              {intradayResponse && <Typography>{intradayResponse}</Typography>}
              {intradayError && <Typography color="error">{intradayError}</Typography>}
            </CardContent>
          </Card>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default Market;
