import React, { useState, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  Card,
  CardContent,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Alert,
  TextField,
  MenuItem,
  CircularProgress,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery
} from "@mui/material";
import ToolBarIcon from '@mui/icons-material/CalendarViewMonthOutlined';
import AddIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/CancelOutlined';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";

const TraderBacktest = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const currentDate = new Date().toLocaleString('en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
  const [entries, setEntries] = useState([]);
  const [currentEntry, setCurrentEntry] = useState({
    id: null,
    image: null,
    profitOrLoss: "",
    category: "",
    symbol: "",
    date: new Date(),
    note: "",
  });
  const [isCustomCategory, setIsCustomCategory] = useState(false);
  const [currentlyEditingCardId, setCurrentlyEditingCardId] = useState(null);
  const [isCustomSymbol, setIsCustomSymbol] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [enlargedImage, setEnlargedImage] = useState(null);

  useEffect(() => {
    const storedEntries = JSON.parse(localStorage.getItem("backtestEntries")) || [];
    setEntries(storedEntries);
  }, []);

  const saveEntriesToLocalStorage = (updatedEntries) => {
    localStorage.setItem("backtestEntries", JSON.stringify(updatedEntries));
  };

  const handleAddEntry = () => {
    setCurrentEntry({
      id: null,
      image: null,
      profitOrLoss: "",
      category: "",
      symbol: "",
      date: new Date(),
      note: "",
    });
    setIsCustomCategory(false);
    setIsCustomSymbol(false);
    setIsEditing(true);
  };

  const handleEditEntry = (entry) => {
    setCurrentEntry(entry);
    setIsCustomCategory(false);
    setIsCustomSymbol(false);
    setIsEditing(true);
  };

  const handleEditInline = (entry) => {
    setCurrentlyEditingCardId(entry.id);
    setCurrentEntry({ ...entry });
  };

  const handleSaveInlineEdit = (id) => {
    const updatedEntries = entries.map((entry) =>
      entry.id === id ? { ...entry, ...currentEntry } : entry
    );
    setEntries(updatedEntries);
    saveEntriesToLocalStorage(updatedEntries);
    setCurrentlyEditingCardId(null);
    setSuccess("Entry updated successfully.");
  };

  const handleSaveEntry = () => {
    if (!currentEntry.profitOrLoss || !currentEntry.category || !currentEntry.symbol || !currentEntry.image) {
      setError("All fields are required.");
      return;
    }

    setLoading(true);
    try {
      let updatedEntries;
      if (currentEntry.id) {
        updatedEntries = entries.map((entry) =>
          entry.id === currentEntry.id ? currentEntry : entry
        );
        setSuccess("Entry updated successfully.");
      } else {
        const newEntry = {
          ...currentEntry,
          id: Date.now(),
        };
        updatedEntries = [...entries, newEntry];
        setSuccess("Entry added successfully.");
      }

      setEntries(updatedEntries);
      saveEntriesToLocalStorage(updatedEntries);
      setIsEditing(false);
      setCurrentEntry({
        id: null,
        image: null,
        profitOrLoss: "",
        category: "",
        symbol: "",
        date: new Date(),
        note: "",
      });
    } catch (err) {
      console.error(err);
      setError("Failed to save entry. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteEntry = (entryId) => {
    const updatedEntries = entries.filter((entry) => entry.id !== entryId);
    setEntries(updatedEntries);
    saveEntriesToLocalStorage(updatedEntries);
    setSuccess("Entry deleted successfully.");
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setCurrentEntry({ ...currentEntry, image: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const getUniqueValues = (key) => {
    return [...new Set(entries.map((entry) => entry[key]))].filter(Boolean);
  };

  const groupedEntries = entries.reduce((groups, entry) => {
    const key = `${entry.category} / ${entry.symbol}`;
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(entry);
    return groups;
  }, {});

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        p: 2,
      }}
    >
      {/* Top AppBar */}
      <AppBar
        position="static"
        sx={{
          mb: 3,
          background: 'linear-gradient(to right, #0a0c10 30%, #0a0c10 90%)',
          boxShadow: '0px 4px 10px rgba(0,0,0,0.5)',
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box display="flex" alignItems="center">
            <ToolBarIcon sx={{ mr: 1, color: theme.palette.secondary.main }} />
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Backtesting
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            {!isMobile && (
              <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
                {currentDate}
              </Typography>
            )}
            <IconButton
              onClick={handleAddEntry}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: "white",
                "&:hover": { backgroundColor: theme.palette.primary.dark },
              }}
            >
              <AddIcon />
            </IconButton>

          </Box>
        </Toolbar>
      </AppBar>

      <Box sx={{ mt: 3 }}>
        {/* Enlarge Image Dialog */}
        <Dialog
          open={!!enlargedImage}
          onClose={() => setEnlargedImage(null)}
          maxWidth="lg"
          fullWidth
          sx={{
            backdropFilter: "blur(8px)",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          }}
        >
          <img
            src={enlargedImage}
            alt="Enlarged"
            style={{
              width: "100%",
              height: "auto",
              objectFit: "contain",
              borderRadius: "8px",
            }}
          />
        </Dialog>
        {Object.keys(groupedEntries).map((groupKey) => (
          <Accordion
            key={groupKey}
            sx={{
              border: `1px solid ${theme.palette.divider}`, // Border style
              mb: 2,
              overflow: "hidden", // Ensures the rounded corners are applied properly
              backgroundColor: theme.palette.background.default,
            }}
          >

            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}>
              <Typography sx={{ color: "white" }}>{groupKey}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display: "grid", gap: 2, gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))" }}>
                {groupedEntries[groupKey].map((entry) => (
                  <Card
                    key={entry.id}
                    sx={{
                      border: `1px solid ${theme.palette.divider}`,
                      backgroundColor: theme.palette.background.paper,
                      color: "white",
                    }}
                  >
                    <CardContent>
                      {currentlyEditingCardId === entry.id ? (
                        // Inline editing mode
                        <>
                          <TextField
                            color="#fff"
                            label="Profit or Loss"
                            value={currentEntry.profitOrLoss}
                            onChange={(e) =>
                              setCurrentEntry({ ...currentEntry, profitOrLoss: e.target.value })
                            }
                            select
                            fullWidth
                            sx={{ mt: 2 }}
                          >
                            <MenuItem
                              value="Profit"
                              sx={{
                                border: `1px solid ${theme.palette.divider}`, // Border style
                                backgroundColor: theme.palette.background.default, // Default background
                                color: '#fff', // Default text color
                                '&:hover': {
                                  backgroundColor: '#444', // Background on hover
                                },
                                '&.Mui-selected': {
                                  backgroundColor: theme.palette.background.default, // Background when selected
                                  color: theme.palette.secondary.main, // Text color when selected
                                  '&:hover': {
                                    backgroundColor: theme.palette.background.default, // Background when selected and hovered
                                  },
                                },
                              }}
                            >
                              Profit
                            </MenuItem>

                            <MenuItem
                              value="Loss"
                              sx={{
                                border: `1px solid ${theme.palette.divider}`, // Border style
                                backgroundColor: theme.palette.background.default, // Default background
                                color: '#fff', // Default text color
                                '&:hover': {
                                  backgroundColor: '#444', // Background on hover
                                },
                                '&.Mui-selected': {
                                  backgroundColor: theme.palette.background.default, // Background when selected
                                  color: theme.palette.secondary.main, // Text color when selected
                                  '&:hover': {
                                    backgroundColor: theme.palette.background.default, // Background when selected and hovered
                                  },
                                },
                              }}
                            >
                              Loss
                            </MenuItem>

                          </TextField>
                          <TextField
                            color="#fff"
                            label="Category"
                            value={currentEntry.category}
                            onChange={(e) =>
                              setCurrentEntry({ ...currentEntry, category: e.target.value })
                            }
                            fullWidth
                            sx={{ mt: 2 }}
                          />
                          <TextField
                            color="#fff"
                            label="Symbol"
                            value={currentEntry.symbol}
                            onChange={(e) =>
                              setCurrentEntry({ ...currentEntry, symbol: e.target.value })
                            }
                            fullWidth
                            sx={{ mt: 2 }}
                          />
                          <TextField
                            label="Date"
                            type="date"
                            value={currentEntry.date}
                            onChange={(e) =>
                              setCurrentEntry({ ...currentEntry, date: e.target.value })
                            }
                            fullWidth
                            sx={{ mt: 2 }}
                          />
                          <TextField
                                                      color="#fff"
                            label="Note"
                            value={currentEntry.note}
                            onChange={(e) =>
                              setCurrentEntry({ ...currentEntry, note: e.target.value })
                            }
                            multiline
                            rows={3}
                            fullWidth
                            sx={{ mt: 2 }}
                          />
                          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                            <IconButton
                              onClick={() => handleSaveInlineEdit(entry.id)}
                              sx={{ color: "white" }}
                            >
                              <SaveIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => setCurrentlyEditingCardId(null)}
                              sx={{ color: "white" }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </>
                      ) : (
                        // Display mode
                        <>
                          <img
                            src={entry.image}
                            alt="Backtest Screenshot"
                            style={{
                              width: "100%",
                              height: "200px",
                              objectFit: "cover",
                              borderRadius: "8px",
                              cursor: "pointer", // Indicate that the image is clickable
                            }}
                            onClick={() => setEnlargedImage(entry.image)}
                          />

                          <Typography
                            sx={{
                              mt: 1,
                              color: entry.profitOrLoss === "Profit" ? "green" : "red",
                            }}
                          >
                            {entry.profitOrLoss}
                          </Typography>
                          <Typography>{entry.category}</Typography>
                          <Typography>{entry.symbol}</Typography>
                          <Typography>
                            {new Date(entry.date).toLocaleDateString()}
                          </Typography>
                          {entry.note && (
                            <Typography variant="body2" color="gray" sx={{ mt: 1 }}>
                              Note: {entry.note}
                            </Typography>
                          )}
                          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                            <IconButton
                              onClick={() => handleEditInline(entry)}
                              sx={{ color: "white" }}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => handleDeleteEntry(entry.id)}
                              sx={{ color: "white" }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </>
                      )}
                    </CardContent>
                  </Card>
                ))}

              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      <Dialog open={isEditing} onClose={() => setIsEditing(false)}
        maxWidth="sm"
        fullWidth
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
            backdropFilter: 'blur(8px)', // Blur effect
          }
        }}
      >
        <DialogTitle sx={{ backgroundColor: theme.palette.background.paper, color: "white" }}>
          {currentEntry.id ? "Edit Entry" : "Add Entry"}
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: theme.palette.background.paper, color: "white" }}>
          <TextField
            color="#fff"
            label="Profit or Loss"
            value={currentEntry.profitOrLoss}
            onChange={(e) => setCurrentEntry({ ...currentEntry, profitOrLoss: e.target.value })}
            select
            fullWidth
            sx={{ mt: 2 }}
          >
<MenuItem
  value="Profit"
  sx={{
    border: `1px solid ${theme.palette.divider}`, // Border style
    backgroundColor: theme.palette.background.default, // Default background
    color: '#fff', // Default text color
    '&:hover': {
      backgroundColor: '#444', // Background on hover
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.background.default, // Background when selected
      color: theme.palette.secondary.main, // Text color when selected
      '&:hover': {
        backgroundColor: theme.palette.background.default, // Background when selected and hovered
      },
    },
  }}
>
  Profit
</MenuItem>

<MenuItem
  value="Loss"
  sx={{
    border: `1px solid ${theme.palette.divider}`, // Border style
    backgroundColor: theme.palette.background.default, // Default background
    color: '#fff', // Default text color
    '&:hover': {
      backgroundColor: '#444', // Background on hover
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.background.default, // Background when selected
      color: theme.palette.secondary.main, // Text color when selected
      '&:hover': {
        backgroundColor: theme.palette.background.default, // Background when selected and hovered
      },
    },
  }}
>
  Loss
</MenuItem>

          </TextField>

          <TextField
            color="#fff"
            label="Category"
            value={isCustomCategory ? "Other" : currentEntry.category}
            onChange={(e) => {
              if (e.target.value === "Other") {
                setIsCustomCategory(true);
                setCurrentEntry({ ...currentEntry, category: "" });
              } else {
                setIsCustomCategory(false);
                setCurrentEntry({ ...currentEntry, category: e.target.value });
              }
            }}
            select
            fullWidth
            sx={{ mt: 2 }}
          >
            {getUniqueValues("category").map((category) => (
              <MenuItem 
              key={category} 
              value={category}
              sx={{
                border: `1px solid ${theme.palette.divider}`, // Border style
                backgroundColor: theme.palette.background.default, // Default background
                color: '#fff', // Default text color
                '&:hover': {
                  backgroundColor: '#444', // Background on hover
                },
                '&.Mui-selected': {
                  backgroundColor: theme.palette.background.default, // Background when selected
                  color: theme.palette.secondary.main, // Text color when selected
                  '&:hover': {
                    backgroundColor: theme.palette.background.default, // Background when selected and hovered
                  },
                },
              }}
            >
                {category}
              </MenuItem>
            ))}
            <MenuItem 
            value="Other"
            sx={{
              border: `1px solid ${theme.palette.divider}`, // Border style
              backgroundColor: theme.palette.background.default, // Default background
              color: '#fff', // Default text color
              '&:hover': {
                backgroundColor: '#444', // Background on hover
              },
              '&.Mui-selected': {
                backgroundColor: theme.palette.background.default, // Background when selected
                color: theme.palette.secondary.main, // Text color when selected
                '&:hover': {
                  backgroundColor: theme.palette.background.default, // Background when selected and hovered
                },
              },
            }}
          >
              Other</MenuItem>
          </TextField>

          {isCustomCategory && (
            <TextField
              color="#fff"
              label="Custom Category"
              value={currentEntry.category}
              onChange={(e) => setCurrentEntry({ ...currentEntry, category: e.target.value })}
              fullWidth
              sx={{ mt: 2 }}
            />
          )}

          <TextField
            color="#fff"
            label="Symbol"
            value={isCustomSymbol ? "Other" : currentEntry.symbol}
            onChange={(e) => {
              if (e.target.value === "Other") {
                setIsCustomSymbol(true);
                setCurrentEntry({ ...currentEntry, symbol: "" });
              } else {
                setIsCustomSymbol(false);
                setCurrentEntry({ ...currentEntry, symbol: e.target.value });
              }
            }}
            select
            fullWidth
            sx={{ mt: 2 }}
          >
            {getUniqueValues("symbol").map((symbol) => (
              <MenuItem 
              key={symbol} 
              value={symbol}
              sx={{
                border: `1px solid ${theme.palette.divider}`, // Border style
                backgroundColor: theme.palette.background.default, // Default background
                color: '#fff', // Default text color
                '&:hover': {
                  backgroundColor: '#444', // Background on hover
                },
                '&.Mui-selected': {
                  backgroundColor: theme.palette.background.default, // Background when selected
                  color: theme.palette.secondary.main, // Text color when selected
                  '&:hover': {
                    backgroundColor: theme.palette.background.default, // Background when selected and hovered
                  },
                },
              }}
            >
                {symbol}
              </MenuItem>
            ))}
            <MenuItem 
            value="Other"
            sx={{
              border: `1px solid ${theme.palette.divider}`, // Border style
              backgroundColor: theme.palette.background.default, // Default background
              color: '#fff', // Default text color
              '&:hover': {
                backgroundColor: '#444', // Background on hover
              },
              '&.Mui-selected': {
                backgroundColor: theme.palette.background.default, // Background when selected
                color: theme.palette.secondary.main, // Text color when selected
                '&:hover': {
                  backgroundColor: theme.palette.background.default, // Background when selected and hovered
                },
              },
            }}
          >
            Other</MenuItem>
          </TextField>

          {isCustomSymbol && (
            <TextField
            color="#fff"
              label="Custom Symbol"
              value={currentEntry.symbol}
              onChange={(e) => setCurrentEntry({ ...currentEntry, symbol: e.target.value })}
              fullWidth
              sx={{ mt: 2 }}
            />
          )}

          <TextField
            color="#fff"
            label="Date"
            type="date"
            value={currentEntry.date}
            onChange={(e) => setCurrentEntry({ ...currentEntry, date: e.target.value })}
            fullWidth
            sx={{ mt: 2 }}
          />

          <TextField
            color="#fff"
            label="Note"
            value={currentEntry.note}
            onChange={(e) => setCurrentEntry({ ...currentEntry, note: e.target.value })}
            multiline
            rows={3}
            fullWidth
            sx={{ mt: 2 }}
          />

          <Box sx={{ mt: 2, textAlign: "center" }}>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: "none" }}
              id="image-upload"
            />
            <label htmlFor="image-upload">
              <Paper
                sx={{
                  display: "inline-block",
                  padding: "10px 20px",
                  backgroundColor: "#2a2a2a",
                  color: "white",
                  cursor: "pointer",
                  textAlign: "center",
                  borderRadius: "8px",
                  border: "1px solid gray",
                }}
              >
                Upload Image
              </Paper>
            </label>
            {currentEntry.image && (
              <Box sx={{ mt: 2, textAlign: "center" }}>
                <Typography variant="body2">Preview:</Typography>
                <img
                  src={currentEntry.image}
                  alt="Preview"
                  style={{
                    width: "100%",
                    maxHeight: "150px",
                    objectFit: "cover",
                    borderRadius: "8px",
                    marginTop: "10px",
                  }}
                  onClick={() => setEnlargedImage(currentEntry.image)}
                />
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: theme.palette.background.paper }}>
          <IconButton onClick={() => setIsEditing(false)} sx={{ color: "white" }}>
            <CloseIcon />
          </IconButton>
          <IconButton onClick={handleSaveEntry} sx={{ color: "white" }}>
            {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : <SaveIcon />}
          </IconButton>
        </DialogActions>
      </Dialog>

      {success && (
        <Snackbar open autoHideDuration={6000} onClose={() => setSuccess("")}>
          <Alert severity="success">{success}</Alert>
        </Snackbar>
      )}

      {error && (
        <Snackbar open autoHideDuration={6000} onClose={() => setError("")}>
          <Alert severity="error">{error}</Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default TraderBacktest;
