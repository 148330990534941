import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Grid,
  Paper,
  Divider,
  Tabs,
  Tab,
  Card,
  CardContent,
  CardHeader,
  Chip,
  TextField,
  IconButton,
  Stack,
  LinearProgress,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  Pagination,
  Switch,
  FormControlLabel,
} from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RefreshIcon from '@mui/icons-material/Refresh';
import api from '../utils/api';

const mockNewsEvents = [
  { time: '8:30 AM ET', event: 'US Non-Farm Payrolls', impact: 'High' },
  { time: '10:00 AM ET', event: 'ISM Manufacturing PMI', impact: 'Medium' },
  { time: '2:00 PM ET', event: 'FOMC Meeting Minutes', impact: 'High' },
];

const mockTopGainers = [
  { symbol: 'AAPL', change: '+3.2%' },
  { symbol: 'TSLA', change: '+2.5%' },
  { symbol: 'NVDA', change: '+1.8%' },
];

const mockTopLosers = [
  { symbol: 'META', change: '-2.1%' },
  { symbol: 'AMZN', change: '-1.5%' },
  { symbol: 'GOOGL', change: '-0.9%' },
];

const mockSectorPerformance = [
  { sector: 'Tech', performance: '+2.5%' },
  { sector: 'Energy', performance: '-0.8%' },
  { sector: 'Financials', performance: '+1.0%' },
  { sector: 'Healthcare', performance: '+0.3%' },
  { sector: 'Industrials', performance: '-0.2%' },
];

const Home = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0);
  const [watchlist, setWatchlist] = useState(['AMD', 'MSFT', 'XLF']);
  const [newSymbol, setNewSymbol] = useState('');

  // State for Indices
  const [indicesData, setIndicesData] = useState([]);
  const [loadingIndices, setLoadingIndices] = useState(true);

  // State for Indices Auto-Refresh
  const [indicesAutoRefresh, setIndicesAutoRefresh] = useState(false);

  // State for AI Market Outlook
  const [aiOutlookData, setAiOutlookData] = useState(null);
  const [loadingAiOutlook, setLoadingAiOutlook] = useState(false);
  let sentimentColor = theme.palette.info.main;

  // State for News
  const [newsData, setNewsData] = useState([]);
  const [loadingNews, setLoadingNews] = useState(true);

  // Pagination states for Top News
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 1;

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Fetch Indices Data
  const fetchIndices = async () => {
    setLoadingIndices(true);
    try {
      const response = await api.get('/indices');
      setIndicesData(response.data);
    } catch (error) {
      console.error('Error fetching indices:', error);
      setIndicesData([]);
    } finally {
      setLoadingIndices(false);
    }
  };

  // Fetch News Data
  const fetchNews = async () => {
    setLoadingNews(true);
    try {
      const response = await api.get('/news');
      setNewsData(response.data);
    } catch (error) {
      console.error('Error fetching News Data:', error);
      setNewsData([]);
    } finally {
      setLoadingNews(false);
    }
  };

  useEffect(() => {
    fetchIndices();
  }, []);

  useEffect(() => {
    fetchNews();
  }, []);

  // Handle Indices Auto-Refresh
  useEffect(() => {
    let intervalId;

    if (indicesAutoRefresh) {
      // Fetch immediately and then set interval to every minute
      fetchIndices();
      intervalId = setInterval(() => {
        fetchIndices();
      }, 60000); // 60,000 ms = 1 minute
    } else {
      // Fetch immediately and then set interval to every day
      fetchIndices();
      intervalId = setInterval(() => {
        fetchIndices();
      }, 86400000); // 86,400,000 ms = 1 day
    }

    // Cleanup on unmount or when indicesAutoRefresh changes
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [indicesAutoRefresh]);

  const handleAddSymbol = () => {
    if (newSymbol.trim() && !watchlist.includes(newSymbol.toUpperCase())) {
      setWatchlist([...watchlist, newSymbol.toUpperCase()]);
      setNewSymbol('');
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const currentDate = new Date().toLocaleString('en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  // Get sentiment color based on AI outlook data
  if (aiOutlookData) {
    sentimentColor =
      aiOutlookData.sentiment === 'Bullish'
        ? theme.palette.success.main
        : aiOutlookData.sentiment === 'Bearish'
          ? theme.palette.error.main
          : theme.palette.info.main;
  }

  // A helper component to represent a clickable Paper "button"
  const ClickablePaper = ({ onClick, children, variant = 'contained', color = 'secondary', sx = {} }) => {
    const bgColor =
      variant === 'contained' ? theme.palette[color]?.main || theme.palette.primary.main : 'transparent';

    const textColor =
      variant === 'contained'
        ? theme.palette[color]?.contrastText || theme.palette.primary.contrastText
        : theme.palette[color]?.main || theme.palette.primary.main;

    const border = variant === 'outlined' ? `1px solid ${textColor}` : 'none';

    return (
      <Paper
        onClick={onClick}
        sx={{
          display: 'inline-block',
          textAlign: 'center',
          p: 1,
          cursor: 'pointer',
          bgcolor: bgColor,
          color: textColor,
          border: border,
          borderRadius: 1,
          ...sx,
        }}
        elevation={2}
        role="button"
        tabIndex={0}
      >
        <Typography variant="body2" sx={{ fontWeight: 600 }}>
          {children}
        </Typography>
      </Paper>
    );
  };

  // Calculate total pages for pagination
  const totalPages = Math.ceil(newsData.length / itemsPerPage);

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    // Optionally, scroll to top of the news section on page change
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Get current page items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentNewsItems = newsData.slice(indexOfFirstItem, indexOfLastItem);

  // Handler for refreshing AI Market Outlook
  const handleRefreshAiOutlook = async () => {
    setLoadingAiOutlook(true);
    try {
      const response = await api.get('/ai-outlook');
      setAiOutlookData(response.data);
    } catch (error) {
      console.error('Error fetching AI Market Outlook:', error);
      setAiOutlookData(null);
    } finally {
      setLoadingAiOutlook(false);
    }
  };

  return (
    <Box sx={{ backgroundColor: theme.palette.background.default, minHeight: '100vh' }}>
      {/* Top AppBar */}
      <AppBar
        position="static"
        sx={{
          mb: 3,
          background: 'linear-gradient(to right, #0a0c10 30%, #0a0c10 90%)',
          boxShadow: '0px 4px 10px rgba(0,0,0,0.5)',
        }}
      >
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
          }}
        >
          {/* App Title and Indices Cards */}
          <Box display="flex" alignItems="center" gap={2}>
            {/* App Title */}
            <Box display="flex" alignItems="center">
              <BarChartIcon sx={{ mr: 1, color: theme.palette.secondary.main }} />
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Market Snapshot
              </Typography>
            </Box>

            {/* Indices Cards */}
            <Box display="flex" alignItems="center" gap={1}>
              {indicesData && indicesData.length > 0 ? (
                indicesData.map((index, idx) => (
                  <Card
                    key={idx}
                    sx={{
                      flex: '0 0 auto',
                      width: '150px', // Wider card
                      height: '40px', // Shorter height for pill shape
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      p: 1,
                      boxShadow: 2,
                      borderRadius: '20px', // Fully rounded edges for pill shape
                      backgroundColor: 'background.paper',
                      border: '1px solid',
                      borderColor: 'divider',
                      transition: 'transform 0.2s',
                      '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: 4,
                      },
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      sx={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {index.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      color='success.main'
                      fontWeight="bold"
                    >
                      ${index.price}
                    </Typography>
                  </Card>
                ))
              ) : (
                <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                  No data
                </Typography>
              )}
            </Box>
          </Box>

          {/* Date */}
          {!isMobile && (
            <Box>
              <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
                {currentDate}
              </Typography>
            </Box>
          )}
        </Toolbar>
      </AppBar>

      {/* SNAPSHOT ROW */}
      <Box sx={{
        px: 2,
        mb: 3
      }}>
        {/* Top News with Pagination */}
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{
            p: 2, height: '100%', boxShadow: 'none', borderRadius: '16px',
            border: `1px solid ${theme.palette.divider}`
          }}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" gutterBottom>
                AI Market Outlook
              </Typography>
              <IconButton
                onClick={handleRefreshAiOutlook}
                size="small"
                aria-label="refresh AI Market Outlook"
              >
                <RefreshIcon />
              </IconButton>
            </Box>
            <Typography variant="caption" gutterBottom>
              Outlook based on yesterday and current close price for S&P 500
            </Typography>
            {loadingAiOutlook ? (
              <Box sx={{ width: '100%' }}>
                <LinearProgress
                  sx={{
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: theme.palette.secondary.main, // Set the bar color
                    },
                    backgroundColor: theme.palette.grey[800], // Set the track color
                  }}
                />
              </Box>
            ) : aiOutlookData ? (
              <Box>
                <LinearProgress
                  variant="determinate"
                  value={
                    aiOutlookData.sentiment === 'Bullish'
                      ? 75
                      : aiOutlookData.sentiment === 'Bearish'
                        ? 25
                        : 50
                  }
                  sx={{
                    mt: 2,
                    height: 6,
                    borderRadius: '4px',
                    backgroundColor: 'rgba(255,255,255,0.1)',
                    '& .MuiLinearProgress-bar': { backgroundColor: sentimentColor },
                  }}
                />
                {/* Two-Column Layout */}
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  {/* Snapshot Column */}
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                      <strong>Snapshot</strong>
                      <hr />
                      <strong>Time:</strong> {aiOutlookData.timerange}
                      <br />
                      <strong>Sentiment:</strong> {aiOutlookData.sentiment}
                      <br />
                      <strong>Confidence:</strong> {aiOutlookData.confidence}
                      <br />
                      <strong>Note:</strong> <em>{aiOutlookData.note}</em>
                    </Typography>
                  </Grid>
                  {/* In-Depth Analysis Column */}
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                      <strong>In Depth Analysis</strong>
                      <hr />
                      <strong>Trend:</strong>
                      <ul>
                        <li><strong>Sentiment:</strong> {aiOutlookData.trend.trend}</li>
                        <li><strong>Confidence:</strong> {aiOutlookData.trend.confidence}</li>
                        <li><strong>Note:</strong> {aiOutlookData.trend.note}</li>
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Typography variant="body2">No AI outlook data available</Typography>
            )}
          </Paper>
        </Grid>
      </Box>

      {/* NEWS ROW */}
      <Box sx={{ px: 2, mb: 3 }}>
        {/* Top News with Pagination */}
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{
            p: 2, height: '100%', boxShadow: 'none', borderRadius: '16px',
            border: `1px solid ${theme.palette.divider}`,
          }}>
            <Typography variant="h6" gutterBottom>
              Top News
            </Typography>
            {loadingNews ? (
              <Box sx={{ width: '100%' }}>
                <LinearProgress
                  sx={{
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: theme.palette.secondary.main, // Set the bar color
                    },
                    backgroundColor: theme.palette.grey[800], // Set the track color
                  }}
                />                </Box>
            ) : (
              <>
                {currentNewsItems && currentNewsItems.length > 0 ? (
                  currentNewsItems.map((news) => (
                    <Box key={news.id} mb={3}>
                      {/* Headline as Clickable Link */}
                      <Typography variant="h6" component="div">
                        <a
                          href={news.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          {news.headline}
                        </a>
                      </Typography>
                      {/* Source and Date */}
                      <Typography variant="subtitle2" color="#9e9e9e">
                        {news.source} - {new Date(news.datetime * 1000).toLocaleString()}
                      </Typography>
                      {/* Summary */}
                      <Typography variant="body2" color="#e0e0e0">
                        {news.summary}
                      </Typography>
                    </Box>
                  ))
                ) : (
                  <Typography variant="body2">No news available.</Typography>
                )}

                {/* Pagination Controls */}
                {totalPages > 1 && (
                  <Box mt={2} display="flex" justifyContent="center">
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                      size="small"
                    />
                  </Box>
                )}
              </>
            )}
          </Paper>
        </Grid>
      </Box>

      {/* TABBED INTERFACE FOR DETAIL */}
      <Box sx={{ px: 2, pb: 2 }}>
        {/* WIP Banner */}
        <Box sx={{
          mb: 2, p: 2, borderRadius: '16px',
          border: `1px solid ${theme.palette.divider}`, backgroundColor: 'transparent', borderRadius: '8px', textAlign: 'center'
        }}>
          <Typography variant="body1" fontWeight="bold" color="text.primary">
            🚧 This section is a Work in Progress (WIP). Features and design are subject to change. 🚧
          </Typography>
        </Box>

        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Market Details" />
          <Tab label="Calendar" />
          <Tab label="Watchlist & Risk" />
          <Tab label="News" />
        </Tabs>
        <Divider sx={{ mb: 2 }} />

        {currentTab === 0 && (
          <Grid container spacing={2}>
            {/* Top Gainers & Losers */}
            <Grid item xs={12} md={6}>
              <Card sx={{ mb: 2, borderRadius: '20px', border: `1px solid ${theme.palette.divider}` }}>
                <CardHeader title="Top Gainers" />
                <Divider />
                <CardContent>
                  {mockTopGainers.map((stock, idx) => (
                    <Box key={idx} display="flex" justifyContent="space-between" mb={1}>
                      <Typography variant="body1">{stock.symbol}</Typography>
                      <Typography variant="body1" color="success.main">
                        {stock.change}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
              </Card>
              <Card sx={{ borderRadius: '20px', border: `1px solid ${theme.palette.divider}` }}>
                <CardHeader title="Top Losers" />
                <Divider />
                <CardContent>
                  {mockTopLosers.map((stock, idx) => (
                    <Box key={idx} display="flex" justifyContent="space-between" mb={1}>
                      <Typography variant="body1">{stock.symbol}</Typography>
                      <Typography variant="body1" color="error.main">
                        {stock.change}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
              </Card>
            </Grid>

            {/* Sector Performance */}
            <Grid item xs={12} md={6}>
              <Card sx={{ height: '100%', borderRadius: '20px', border: `1px solid ${theme.palette.divider}` }}>
                <CardHeader title="Sector Performance" />
                <Divider />
                <CardContent>
                  <Box display="flex" flexWrap="wrap" justifyContent="space-between">
                    {mockSectorPerformance.map((sector, idx) => (
                      <Box key={idx} width="45%" mb={2}>
                        <Typography variant="body1">
                          {sector.sector}:
                          <Typography
                            component="span"
                            variant="body1"
                            color={sector.performance.startsWith('+') ? 'success.main' : 'error.main'}
                            sx={{ ml: 1 }}
                          >
                            {sector.performance}
                          </Typography>
                        </Typography>
                        <Box
                          sx={{
                            mt: 1,
                            height: 20,
                            width: '100%',
                            backgroundColor: sector.performance.startsWith('+') ? 'success.main' : 'error.main',
                            borderRadius: '4px',
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}

        {currentTab === 1 && (
          <Card sx={{ borderRadius: '20px', border: `1px solid ${theme.palette.divider}` }}>
            <CardHeader title="Upcoming Economic Events" />
            <Divider />
            <CardContent>
              <List>
                {mockNewsEvents.map((event, idx) => (
                  <ListItem key={idx} disableGutters>
                    <ListItemText
                      primary={`${event.time}: ${event.event}`}
                      secondary={`Impact: ${event.impact}`}
                    />
                  </ListItem>
                ))}
              </List>
              <Box mt={2} textAlign="right">
                <ClickablePaper onClick={() => navigate('/calendar')} variant="contained">
                  Full Calendar
                </ClickablePaper>
              </Box>
            </CardContent>
          </Card>
        )}

        {currentTab === 2 && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card sx={{ mb: 2, borderRadius: '20px', border: `1px solid ${theme.palette.divider}` }}>
                <CardHeader title="Watchlist" />
                <Divider />
                <CardContent>
                  <Typography variant="body2" gutterBottom>
                    Currently Watching:
                  </Typography>
                  <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', mb: 2 }}>
                    {watchlist.map((ticker, idx) => (
                      <Chip key={idx} label={ticker} color="secondary" />
                    ))}
                  </Stack>
                  <Box display="flex" alignItems="center">
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Add Symbol"
                      value={newSymbol}
                      onChange={(e) => setNewSymbol(e.target.value)}
                      sx={{ mr: 1 }}
                    />
                    <IconButton color="primary" onClick={handleAddSymbol}>
                      <AddCircleIcon />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card sx={{ borderRadius: '20px', border: `1px solid ${theme.palette.divider}` }}>
                <CardHeader title="Risk Management Tips" />
                <Divider />
                <CardContent>
                  <List sx={{ pl: 2 }}>
                    <ListItem disableGutters>
                      <ListItemText primary="- Set a daily loss limit" />
                    </ListItem>
                    <ListItem disableGutters>
                      <ListItemText primary="- Stick to your trading plan" />
                    </ListItem>
                    <ListItem disableGutters>
                      <ListItemText primary="- Use proper position sizing" />
                    </ListItem>
                    <ListItem disableGutters>
                      <ListItemText primary="- Manage your leverage carefully" />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}

        {currentTab === 3 && (
          <Card sx={{ borderRadius: '20px', border: `1px solid ${theme.palette.divider}` }}>
            <CardHeader title="Latest Headlines" />
            <Divider />
            <CardContent>
              {newsData && newsData.length > 0 ? (
                <>
                  {newsData.map((news) => (
                    <Box key={news.id} mb={3}>
                      {/* Headline as Clickable Link */}
                      <Typography variant="h6" component="div">
                        <a
                          href={news.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          {news.headline}
                        </a>
                      </Typography>
                      {/* Source and Date */}
                      <Typography variant="subtitle2" color="#9e9e9e">
                        {news.source} - {new Date(news.datetime * 1000).toLocaleString()}
                      </Typography>
                      {/* Summary */}
                      <Typography variant="body2" color="#e0e0e0">
                        {news.summary}
                      </Typography>
                    </Box>
                  ))}

                  {/* Pagination Controls */}
                  {totalPages > 1 && (
                    <Box mt={2} display="flex" justifyContent="center">
                      <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                        size="small"
                      />
                    </Box>
                  )}
                </>
              ) : (
                <Typography variant="body2">No news available.</Typography>
              )}
              <Box mt={2} textAlign="right">
                <ClickablePaper onClick={() => navigate('/news')} variant="outlined">
                  View More News
                </ClickablePaper>
              </Box>
            </CardContent>
          </Card>
        )}
      </Box>
    </Box>
  );
};

export default Home;
