import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Typography
} from '@mui/material';
import GridViewIcon from '@mui/icons-material/GridView';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { PROJECT_NAME } from '../constants';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/AutoGraphRounded';
import TraderBacktestIcon from '@mui/icons-material/CalendarViewMonthOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Navbar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false); // State for logout dialog
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    setIsLoggedIn(!!authToken);
  }, [location]);

  const handleToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsLoggedIn(false);
    navigate('/login');
  };

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const openLogoutDialog = () => {
    setLogoutDialogOpen(true);
  };

  const closeLogoutDialog = () => {
    setLogoutDialogOpen(false);
  };

  const hideNavbarPaths = ['/login'];

  if (hideNavbarPaths.includes(location.pathname)) {
    return null;
  }

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: '#0000000',
          boxShadow: 'none',
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Box
              sx={{
                fontFamily: "'Poppins', sans-serif",
                fontWeight: 700,
                letterSpacing: '0.05em',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                background: 'linear-gradient(145deg, #ff9f43, #ff6f61)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundClip: 'text',
                color: 'transparent',
              }}
            >
              {isMobile ? PROJECT_NAME : PROJECT_NAME}
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            {/* Navigation Links */}
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Tooltip title="Home" arrow>
                <NavLink
                  to="/home"
                  style={({ isActive }) => ({
                    color: isActive ? '#ff6f61' : '#FFFFFF',
                    textDecoration: 'none',
                  })}
                >
                  <IconButton>
                    <HomeIcon />
                  </IconButton>
                </NavLink>
              </Tooltip>
              <Tooltip title="Trader Backtest Journal" arrow>
                <NavLink
                  to="/trader-backtest"
                  style={({ isActive }) => ({
                    color: isActive ? '#ff6f61' : '#FFFFFF',
                    textDecoration: 'none',
                  })}
                >
                  <IconButton>
                    <TraderBacktestIcon />
                  </IconButton>
                </NavLink>
              </Tooltip>
              <Tooltip title="Trader Dashboard" arrow>
                <NavLink
                  to="/trader-dashboard"
                  style={({ isActive }) => ({
                    color: isActive ? '#ff6f61' : '#FFFFFF',
                    textDecoration: 'none',
                  })}
                >
                  <IconButton>
                    <DashboardIcon />
                  </IconButton>
                </NavLink>
              </Tooltip>
            </Box>

            {isLoggedIn && (
              <Tooltip title="Logout" arrow>
                <IconButton
                  onClick={openLogoutDialog} // Open the confirmation dialog
                  sx={{
                    color: '#FFFFFF',
                    transition: 'transform 0.3s ease, color 0.3s ease',
                    '&:hover': {
                      color: '#e33e3e',
                      transform: 'scale(1.2)',
                    },
                  }}
                >
                  <ExitToAppIcon />
                </IconButton>
              </Tooltip>
            )}

            {(location.pathname !== '/home' && location.pathname !== '') && (
              <IconButton
                onClick={handleBack}
                sx={{
                  color: '#FFFFFF',
                  transition: 'transform 0.3s ease, color 0.3s ease',
                  '&:hover': {
                    color: '#ff9f43',
                    transform: 'scale(1.2)',
                  },
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      {/* Logout Confirmation Dialog */}
      <Dialog
        open={logoutDialogOpen}
        onClose={closeLogoutDialog}
        PaperProps={{
          sx: {
            borderRadius: '16px',
            padding: '16px',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
          }
        }}
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
            backdropFilter: 'blur(8px)', // Blur effect
          },
        }}
      >
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          Are you sure you want to log out?
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
          {/* Cancel Button as Paper */}
          <Paper
            elevation={2}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              p: 1,
              borderRadius: '12px',
              background: theme.palette.background.paper,
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: `0px 0px 6px 2px ${theme.palette.secondary.main}`,
              },
            }}
            onClick={closeLogoutDialog}
            role="button"
            tabIndex={0}
          >
            <Typography>Cancel</Typography>
          </Paper>

          {/* Logout Button as Paper */}
          <Paper
            elevation={2}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              p: 1,
              borderRadius: '12px',
              background: theme.palette.error.main,
              color: theme.palette.error.contrastText,
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: `0px 0px 6px 2px ${theme.palette.error.light}`,
              },
            }}
            onClick={handleLogout}
            role="button"
            tabIndex={0}
          >
            <Typography>Logout</Typography>
          </Paper>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default Navbar;
